<template>
    <div>
      <h4 style="text-align: center">Update Variables</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>Price %</th>
            <th>Stock %</th>
            <th></th>
          </tr>
          <tr v-for="stock in update_stock" :key="stock.type">
            <td>{{ stock.site }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.price_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_perc" />
            </td>
            <td>
              <a id="updateValues" @click="updateStockVariables(stock)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="text-align: center">Stock Status</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Tipo</th>
            <th>Stock Min.</th>
            <th>Stock Máx.</th>
            <th>Preço Aumento %</th>
            <th></th>
          </tr>
          <tr v-for="stock in stock_status" :key="stock.type">
            <td>{{ stock.type }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_min" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_max" />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                v-model="stock.price_change"
                :disabled="shouldDisable(stock.type)"
              />
            </td>
            <td>
              <a id="updateValues" @click="updateStockStatus(stock)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="text-align: center">Price Options</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Level</th>
            <th>Margem</th>
            <th>Validade</th>
            <th>Última Receção</th>
            <th>Última Venda</th>
            <th>Stock</th>
            <th>Auto</th>
            <th></th>
          </tr>
          <tr v-for="price in price_options" :key="price.level">
            <td>{{ price.level }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.validity" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.last_reception" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.last_sale" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.stock" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.auto_days" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceOptions(price)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default{
    data: function(){
        return{
            update_stock: [],
            stock_status: [],
            price_options: [],
        }
    },
    methods: {
        async getStockUpdateVar() {
            try {
                await this.$store.dispatch('getStockUpdateVar');
                this.update_stock = this.getStockVariables;
            } catch (err) {
                alert(err);
            }
        },
        async getStockStatusVar() {
            try {
                await this.$store.dispatch('getStockStatusVar');
                console.log("boooooas", this.getStockStatus);
                this.stock_status = this.getStockStatus;
            } catch (err) {
                alert(err);
            }
        },
        async getPriceOptionsVar() {
            try {
                await this.$store.dispatch('getPriceOptionsVar');
                console.log(this.getPriceOptions);
                this.price_options = this.getPriceOptions;
                console.log('AQUIII', this.price_options, this.getPriceOptions);
            } catch (err) {
                alert(err);
            }
        },
        shouldDisable(type) {
            return type === 'Infinity' || type === 'MinC No';
        },
        async updateStockStatus(status) {
            this.$swal({
                title: 'Quer atualizar ' + status.type + '?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, atualizar!',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                try {
                    await this.$store.dispatch('updateStockStatusVar', {
                    type: status.type,
                    max: status.stock_max,
                    min: status.stock_min,
                    price: status.price_change,
                    });
                    this.$swal({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Intervalo atualizado`,
                    showConfirmButton: false,
                    timer: 1500,
                    });
                    await this.getIntervalos();
                } catch (error) {
                    alert(error);
                }
                }
            });
        },
        async getIntervalos() {
            try {
                await this.$store.dispatch('getPriceIntervalos');
                this.intervalos = this.getPriceIntervalos;
                this.intervalos_show = this.intervalos.filter((item) => item.site === this.intervalo);
            } catch (err) {
                alert(err);
            }
        },
        async loadData(){
            await this.getIntervalos();
            await this.getPriceOptionsVar();
            await this.getStockUpdateVar();
            await this.getStockStatusVar();
        },
        async updateStockVariables(site_var) {
          this.$swal({
            title: 'Quer atualizar ' + site_var.site + '?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, atualizar!',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('updateStockVariables', {
                  site: site_var.site,
                  price_perc: site_var.price_perc,
                  stock_perc: site_var.stock_perc,
                });
                this.$swal({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: `Variáveis atualizadas`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                await this.getIntervalos();
              } catch (error) {
                alert(error);
              }
            }
          });
        },
        async updatePriceOptions(status) {
          this.$swal({
            title: 'Quer atualizar ' + status.level + '?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, atualizar!',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('updatePriceOptionsVar', {
                  level: status.level,
                  margin: status.margin,
                  validity: status.validity,
                  last_reception: status.last_reception,
                  last_sale: status.last_sale,
                  stock: status.stock,
                  auto_days: status.auto_days,
                });
                this.$swal({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: `Intervalo atualizado`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                await this.getIntervalos();
              } catch (error) {
                alert(error);
              }
            }
          });
        },
    },
    created(){
        this.loadData();
    },
    computed: {
        ...mapGetters(['getStockVariables', 'getStockStatus', 'getPriceOptions', 'getPriceIntervalos'])
    }
}

</script>

<style>
.container {
  text-align: center;
}
.validationAdress {
  text-align: center;
  margin: 0px 600px 0px 600px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.table {
  text-align: center;
}
</style>

